
import { defineComponent, onMounted, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';

interface ProfileDetails {
  avatar: string;
  name: string;
  surname: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

export default defineComponent({
  name: 'account-settings',
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label('First name'),
      lname: Yup.string().required().label('Last name'),
      company: Yup.string().required().label('Company'),
      phone: Yup.string().required().label('Contact phone'),
      website: Yup.string().label('Webside'),
      country: Yup.string().required().label('Country'),
      language: Yup.string().required().label('Language'),
      timezone: Yup.string().required().label('Timezone'),
      currency: Yup.string().required().label('Currency'),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label('Email'),
      confirmemailpassword: Yup.string().required().label('Password'),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required().label('Current password'),
      newpassword: Yup.string().min(4).required().label('Password'),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
        .label('Password Confirmation'),
    });

    const profileDetails = ref<ProfileDetails>({
      avatar: 'media/avatars/150-2.jpg',
      name: 'Max',
      surname: 'Smith',
      company: 'Keenthemes',
      contactPhone: '044 3276 454 935',
      companySite: 'keenthemes.com',
      country: 'MY',
      language: 'msa',
      timezone: 'Kuala Lumpur',
      currency: 'USD',
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
    });

    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          submitButton1.value?.removeAttribute('data-kt-indicator');
        }, 2000);
      }
    };

    const saveChanges2 = () => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          submitButton2.value?.removeAttribute('data-kt-indicator');
        }, 2000);
      }
    };

    const saveChanges3 = () => {
      if (submitButton3.value) {
        // Activate indicator
        submitButton3.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          submitButton3.value?.removeAttribute('data-kt-indicator');
        }, 2000);
      }
    };

    const saveChanges4 = () => {
      if (submitButton4.value) {
        // Activate indicator
        submitButton4.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          submitButton4.value?.removeAttribute('data-kt-indicator');
        }, 2000);
      }
    };

    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          submitButton5.value?.removeAttribute('data-kt-indicator');

          Swal.fire({
            text: 'Email is successfully changed!',
            icon: 'success',
            confirmButtonText: 'Ok',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emailFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute('data-kt-indicator');

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute('data-kt-indicator', 'on');

        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute('data-kt-indicator');

          Swal.fire({
            text: 'Password is successfully changed!',
            icon: 'success',
            confirmButtonText: 'Ok',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            passwordFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = 'media/avatars/blank.png';
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs('Settings', ['Account']);
    });

    return {
      submitButton1,
      submitButton2,
      submitButton3,
      submitButton4,
      submitButton5,
      saveChanges1,
      saveChanges2,
      saveChanges3,
      saveChanges4,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
    };
  },
});
